<template>
	<div>
		<div v-if="searchAISuggestionsFeature">
			<h2>AI Suggested Flashcards</h2>
			<ul
				class="c-deck-info-cards-grid"
				style="padding: 0;"
			>
				<li
					v-for="(deck, index) in (streamedJSON.decks || [])"
					:key="index"
					class="c-deck-info-card"
				>
					<div class="c-deck-info-card__section">
						<div style="display: flex; justify-content: space-between; flex-direction: column; flex-grow: 1;">
							<span class="c-deck-info-card__title"><strong>{{ deck.title }}</strong></span>
							<div style="display: flex; flex-direction: column;">
								<p>{{ deck.description }}</p>
								<p>{{ deck.fieldOfStudy }}</p>
								<button
									style="align-self: end;"
									type="button"
									class="c-btn c-btn--cta c-btn--small"
								>
									Generate Deck
								</button>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<ul>
				<li
					v-for="suggestion in streamedJSON.suggestions"
					:key="suggestion"
				>
					{{ suggestion }}
				</li>
			</ul>
			<ul>
				<li
					v-for="(value, key) in streamedJSON.dataCollectedSoFar"
					:key="key"
				>
					{{ key }}: {{ value != null ? value : 'null' }}
				</li>
			</ul>
		</div>

		<DecksContainer
			:decks="decks"
			:title="$t('dashboard.searchResultsForQuery', { query })"
			:fetch-decks="fetchDecks"
			:request-more-decks="requestMoreDecks"
			:results-amount="resultsAmount"
		/>
	</div>
</template>
<script>
	// @TODO: [ ] Deck search doesn't not show deck which are private but created by author - author should be able to find them.
	import { fetchStream } from '@/assets/js/utils/fetchStream';
	import { mapGetters } from 'vuex';
	import DecksContainer from '@/components/dashboard/DecksContainer.vue';

	export default {
		name: 'Search',
		components: {
			DecksContainer,
		},
		data() {
			return {
				streamedJSON: {},
			};
		},
		computed: {
			...mapGetters(['feature']),
			searchAISuggestionsFeature() {
				return this.feature('searchAISuggestions');
			},
			resultsAmount() {
				return this.$store.getters.queryLists(this.query).length;
			},
			query() {
				return this.$route.query.query;
			},
			decks() {
				if (!this.query) {
					return [];
				}

				return this.$store.getters.queryLists(this.query);
			},
			requestMoreDecks() {
				return () => this.$store.dispatch('loadModeSearchDecks', this.query);
			},
		},
		async created() {
			if (this.searchAISuggestionsFeature) {
				const params = new URLSearchParams({
					query: this.query,
					amount: 6,
				});

				await fetchStream({ url: `/generator/create-deck-suggestions?${params}` }, ({ fixedJSON }) => {
					this.streamedJSON = fixedJSON;
				});
			}
		},
		methods: {
			async fetchDecks() {
				if (!this.$store.getters.queryContainsResults(this.query)) {
					console.log(1);
					await this.$store.dispatch('searchDecks', this.query);
				}
			},
		},
	};
</script>
